import React from "react";
import VideoForm from "./VideoForm";
import Banner from "./Banner";
import { Helmet } from "react-helmet";
import UseFetch, { WebsiteSetting, PageMETATag } from "./UseFetch";

export default function About() {

  const [bannerdata] = UseFetch();
  const [webSetting] = WebsiteSetting();
  const [PagemetaTags] = PageMETATag();
 
  return (
    <>
      <Helmet>
        <title>{PagemetaTags.AboutMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.AboutMetaKeyword} />
        <meta name="description" content={PagemetaTags.AboutMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Banner
        Bnanerimage={bannerdata.about_banner}
        image_title={bannerdata.about_banner_title}
        image_alt={bannerdata.about_banner_alt}
      />
      <section className="about_section section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
            <div className="col-md-2">
              <div className="section_title">
                <h2 className="title text-dark">About</h2>
              </div>
            </div>
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="about_img_box">
                <img src={webSetting.about_img} className="about_img" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="about_text_box">
                <h1 className="about_title">{webSetting.about_title}</h1>
                <p
                  className="about_desc"
                  dangerouslySetInnerHTML={{
                    __html: webSetting.about_desc,
                  }}
                ></p>

                {/* <a href="/about" className="about_btn">
                  Know More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <VideoForm
        IframeVideo={webSetting.iframe_video}
        SectionTitle={webSetting.video_form_title}
      />
    </>
  );
}
