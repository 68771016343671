import { useState, useEffect } from "react";
import Banner from "./Banner";
import BlogPageBox from "./BlogPageBox";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import UseFetch, {LangMatatag } from "./UseFetch";

export default function Blog() {
  const Site_Url = "https://namechangeservice.in/";
  const BASE_URL = Site_Url + "admin/API/";
  let { language } = useParams();

  const [bannerdata] = UseFetch();
  // const [Blogs] = BlogPageBlog(language);
  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}blogAPI.php`);
      const jsonData = await response.json();
      const resultfilter = jsonData.filter((langcat) => {
        return langcat.lang === language;
      });
      setBlogsdata(resultfilter);
    };
    AllBlogs();
  }, [language, BASE_URL]);

  const [Langmeta] = LangMatatag(language);

  return (
    <>
    <Helmet>

      {Langmeta.metatitle && (
        <title>{Langmeta.metatitle}</title>
      )}
        <meta name="keywords" content={Langmeta.metalink} />
        <meta name="description" content={Langmeta.metadescription} />
        <link rel="canonical" href={window.location.href} />
      
    </Helmet>
  
      <Banner
        Bnanerimage={bannerdata.blog_banner}
        image_title={bannerdata.blog_banner_title}
        image_alt={bannerdata.blog_banner_alt}
      />

      {/* Blog section Start */}
      <section className="blog_section section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            {Blogs.map((value, index) => (
              <BlogPageBox
                key={index}
                slug={value.slug}
                lang={value.lang}
                image={value.sort_img}
                image_title={value.image_title}
                image_alt={value.image_alt}
                date={value.date}
                title={value.title}
                tagline={value.tagline}
              />
            ))}
          </div>
        </div>
      </section>
      {/* Blog section End */}
    </>
  );
}
