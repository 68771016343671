import axios from "axios";
import { useState, useEffect } from "react";

const Site_Url = "https://namechangeservice.in/";
const BASE_URL = Site_Url + "admin/API/";

const UseFetch = () => {
  // Banner API
  const [bannerdata, setbannerdata] = useState([]);
  //   useEffect(() => {
  //     const AllBanner = async () => {
  //       const response = await fetch(`${BASE_URL}bannerAPI.php`);
  //       const jsonData = await response.json();
  //       setbannerdata(jsonData);
  //     };
  //     AllBanner();
  //   }, [BASE_URL]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}bannerAPI.php`)
      .then((response) => setbannerdata(response.data));
  }, []);
  return [bannerdata];
};

// WebSite Setting
const WebsiteSetting = () => {
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}website_settingAPI.php`)
      .then((response) => setwebSetting(response.data));
  }, []);
  //   useEffect(() => {
  //     fetch(`${BASE_URL}website_settingAPI.php`)
  //       .then((res) => res.json())
  //       .then((data) => setwebSetting(data));
  //   }, []);
  return [webSetting];
};

//Page Meta Tags
const PageMETATag = () => {
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}pagemetaTags.php`)
      .then((response) => setPagemetaTags(response.data));
  }, []);
  return [PagemetaTags];
};

//Testimonial API
const ClientDATA = () => {
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}clientAPI.php`).then((response) => {
      setclientdata(response.data);
    });
  }, []);
  return [clientdata];
};
// WhyCHOOSE;
const WhyCHOOSE = () => {
  const [whyChoosedata, setwhyChoosedata] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}whychooseAPI.php`).then((response) => {
      setwhyChoosedata(response.data);
    });
  }, []);
  return [whyChoosedata];
};

// Services
const ServiceDATA = () => {
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}serviceAPI.php`).then((response) => {
      setservicedata(response.data);
    });
  }, []);
  return [servicedata];
};
// Content
const ContentDATA = () => {
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}contentAPI.php`).then((response) => {
      setcontentdata(response.data);
    });
  }, []);

  return [contentdata];
};

// Home Blogs
const HomePageBLOG = () => {
  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}homeblogAPI.php`).then((response) => {
      setBlogsdata(response.data);
    });
  }, []);
  return [Blogs];
};

// Blog Page Blogs
const BlogPageBlog = (language) => {
  const [BlogPage, setBlogPagedata] = useState([]);
  //   useEffect(() => {
  //     const AllBlogs = async () => {
  //       const response = await fetch(`${BASE_URL}blogAPI.php`);
  //       const jsonData = await response.json();
  //       const resultfilter = jsonData.filter((langcat) => {
  //         return langcat.lang === language;
  //       });
  //       setBlogsdata(resultfilter);
  //     };
  //     AllBlogs();
  //   }, [language]);

  useEffect(() => {
    axios.get(`${BASE_URL}blogAPI.php`).then((response) => {
      setBlogPagedata(
        response.data.filter((langcat) => langcat.lang === language)
      );
    });
  });

  return [BlogPage];
};

// Blog Details
const BlogDETAILS = (slug) => {
  const [Blogdetails, setBlogdetailsdetails] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}blogDetailsAPI.php?slugname=${slug}`)
      .then((response) => {
        setBlogdetailsdetails(response.data);
      });
  }, [slug]);

  return [Blogdetails];
};

// Blog Lang MAta tags
const LangMatatag = (language) => {
  const [langtags, setlangtags] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}alllanguages.php?langcat=${language}`)
      .then((response) => {
        setlangtags(response.data);
      });
  }, [language]);

  return [langtags];
};

// Blog Details Blog
const BlogDetailsBLOG = (Catlang) => {
  const [BlogdetailsBlogs, setBlogdetailsBlogs] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}catblogAPI.php?langcat=${Catlang}`)
      .then((response) => {
        setBlogdetailsBlogs(response.data);
      });
  });
  return [BlogdetailsBlogs];
};

export {
  WebsiteSetting,
  PageMETATag,
  ClientDATA,
  WhyCHOOSE,
  ServiceDATA,
  ContentDATA,
  HomePageBLOG,
  BlogPageBlog,
  BlogDETAILS,
  BlogDetailsBLOG,
  LangMatatag,
};
export default UseFetch;
